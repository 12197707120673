import React from 'react';
import styled from '@emotion/styled';

type Props = {
	setHidden: React.Dispatch<React.SetStateAction<boolean>>;
};

const CenteredComponent: React.FC<Props> = ({ setHidden }) => {
	const handleClick = () => {
		setHidden(false);
	};

	return (
		<div style={styles.container}>
			<h1>걸어본이 온더월 클라우드로 리뉴얼 되었습니다 :)</h1>
			<br />
			<p>새로운 서비스로 접속해주세요</p>
			<br />
			<br />

			<a href="https://onthewall.io" style={styles.button}>
				바로가기
			</a>
			<HiddenBtn onClick={() => handleClick()}>hidden</HiddenBtn>
		</div>
	);
};

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column' as 'column',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100vh',
		textAlign: 'center' as 'center',
	},
	button: {
		padding: '10px 20px',
		backgroundColor: '#007BFF',
		color: 'white',
		textDecoration: 'none' as 'none',
		borderRadius: '5px',
		marginTop: '20px',
	},
};

export default CenteredComponent;

const HiddenBtn = styled.div`
	position: fixed;
	bottom: 0;
	right: 0;
	/* background-color: #007bff; */
	color: white;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
	margin: 20px;
`;
