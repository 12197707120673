import MomentUtils from '@date-io/moment';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { SnackbarProvider } from 'notistack';
import type { FC } from 'react';
import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import GlobalStyles from './components/GlobalStyles';
import GoogleAnalytics from './components/GoogleAnalytics';
import ScrollReset from './components/ScrollReset';
import { AuthProvider } from './contexts/FirebaseAuthContext';
import useSettings from './hooks/useSettings';
import { initializeI18next } from './plugins/i18next';
import { createTheme } from './theme';
import Temp from './Temp';
import routes, { renderRoutes } from './routes';
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App: FC = () => {
	const { settings } = useSettings();
	const [hiddenBtn, setHiddenBtn] = React.useState(true);
	useEffect(() => {
		window.open('https://onthewall.io');
		initializeI18next();
	}, []);
	const theme = createTheme({
		direction: settings.direction,
		responsiveFontSizes: settings.responsiveFontSizes,
		theme: settings.theme,
	});

	return (
		<ThemeProvider theme={theme}>
			<StylesProvider jss={jss}>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<SnackbarProvider dense maxSnack={3}>
						{/* <I18nextProvider i18n={i18n}> */}
						<Router history={history}>
							<AuthProvider>
								<GlobalStyles />
								<ScrollReset />
								<GoogleAnalytics />
								{hiddenBtn && <Temp setHidden={setHiddenBtn}></Temp>}
								{!hiddenBtn && renderRoutes(routes)}
							</AuthProvider>
						</Router>
						{/* </I18nextProvider> */}
					</SnackbarProvider>
				</MuiPickersUtilsProvider>
			</StylesProvider>
		</ThemeProvider>
	);
};

export default App;
